'use strict';

const jwtDecode = require('jwt-decode');
const TokenStore = require('donny-auth');
const rivets = require('rivets');
const axios  = require('axios');
const QCodeDecoder  = require('qcode-decoder/build/qcode-decoder.min');

class Auth {
    constructor (
        domain=window.env.AUTH_STORE_DOMAIN
    ) {
        this.store = new TokenStore(domain);

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.getUserProfile = this.getUserProfile.bind(this);

        Auth.instance = this;
    }

    login() {
        location.href = this.store.loginUrl;
    }

    logout() {
        return this.store.removeToken({aud: 'vizia.brandwatch.com'});
    }

    isAuthenticated() {
        return this.store.getToken({aud: 'vizia.brandwatch.com'});
    }

    getUserProfile() {
        return this.store.getToken({aud: 'vizia.brandwatch.com'}).then(jwtDecode).then(user => {
            return Object.assign(user, {
                name: `${user.firstName} ${user.lastName}`
            });
        });
    }

    getOTP(deckId) {
        const audience = 'vizia.brandwatch.com/foot-gun';
        const scope = {};

        scope[audience] = {
            resources: [{
                type: 'deck',
                id: deckId
            }]
        };

        return this.store.getOTP({
            aud: [audience],
            scope
        });
    }
}

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

let model = {
    connected: false,
    loading: true
};

module.exports = {
    initControl: () => {
        window.a = new Auth('https://auth-gateway.platform-stage.gcp0.bwcom.net/store');
        a.isAuthenticated()
            .then(token => {
                if(!token) {
                    return a.login();
                }
                return a.getUserProfile()
            })
            .then(bindUI)
    },
    bindUI: () => {
        rivets.bind(document.querySelector('#container'), model);
        initializeControls();
    },
    initializeControls: () => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');

        showProfile();
        if(!id) {
            return scanPage();
        }

        model.connected = true;
        model.screenId = id;
        showFolders();
    },
    scanPage: () => {
        if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'environment'
                }
            }).then(function(stream) {
                document.querySelector('video').srcObject = stream;
                document.querySelector('video').play();
            });
        }

        QCodeDecoder()
            .decodeFromVideo(document.querySelector('video'), function (err, result) {
                if (err) throw err;
                if(validURL(result)) {
                    window.location = result;
                } else {
                    alert('Incorrect QR Code, try again?');
                }
            });

        model.loading = false;
    },
    showProfile: (user) => {
        a.getUserProfile()
            .then(user => {
                model.user = user;
                rivets.bind(document.querySelector('#appbar'), {user})
            })
    },
    showFolders: () => {
        a.isAuthenticated()
            .then(token => {
                return axios.get('https://tycho.vizia-stage.gcp0.bwcom.net/v1/folders/?withPermissions', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            })
            .then(data => data.data)
            .then(folders => {
                model.loading = false;
                model.folders = folders;
                model.folder =  {
                    selected: 0,
                    results: []
                };
            });
    },
    showFolder: (id) => {
        model.loading = true;
        a.isAuthenticated()
            .then(token => {
                return axios.get(`https://tycho.vizia-stage.gcp0.bwcom.net/v1/folders/${id}/decks`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            })
            .then(data => data.data)
            .then(data => {
                model.loading = false;
                model.folder = Object.assign(model.folders.find(folder => folder.id.toString() === id), data, {selected: id});
            });
    },
    selectFolder: (el) => {
        const id = el.target.dataset.folder;
        console.log('load folder:', id);

        showFolder(id);
    },
    selectDeck: (el) => {
        const id = el.target.dataset.deck;
        console.log('selected deck:', id);

        sendDeck(id);
    },
    backToFolders: () => {
        model.folder.selected = 0;
    },
    sendDeck: (id) => {
        model.loading = true;
        a.getOTP(parseInt(id, 10))
            .then(token => {
                return axios.post(`//${window.location.host}/distribute/${id}?screen=${model.screenId}`, {token});
            })
            .then(() => {
                alert('distribution success...');
                window.location =  `http://${window.location.host}/control`;
            })
            .catch(e => {
                alert('distribution failed...');
                window.location =  `http://${window.location.host}/control`;
            });
    },
    logout: () => {
        a.logout();
        window.location.reload();
    },
    validateCode: (e) => {
        const blocks = [].slice.call(e.target.parentElement.querySelectorAll('input'));
        const code = blocks.map(block => block.value.toLowerCase()).join('');
        if(code.length === 9) {
            window.location = `./?id=${code}`;
        } else  if(e.target.value.length === 3 && e.target.nextElementSibling) {
            e.target.nextElementSibling.focus();
        }
    }
};
