'use strict';

const rivets = require('rivets');

let model;

window.startScreen = (container) => {
    const id = createID();
    const qr = "https://chart.googleapis.com/chart?cht=qr&" +
        "chs=512x512&" +
        `chl=${window.location.protocol}//${window.location.host}/control?id=${id}&` +
        "choe=UTF-8";

    model = {
        qr,
        code: getDisplayCodeParts(id),
        host: `${window.location.protocol}//${window.location.host}`,
        ready: false
    };

    rivets.bind(document.querySelector('#main-container'), model);
    listenForConnections(id);
};

function listenForConnections(id) {
    const proto = window.location.protocol == 'https:' ? 'wss' : 'ws';
    const ws = new WebSocket(`${proto}://${window.location.host}`);
    ws.addEventListener('message', data => {
        const json = JSON.parse(data.data);
        if(json.distribute) {
            model.ready = false;
            window.location = `https://vizia-stage.gcp0.bwcom.net/?sceneIndex=0&deckId=${json.deck}&otp=${json.token}`;
        } else {
            console.log('unknown message', json);
        }
    });
    ws.addEventListener('open', () => {
        ws.send(JSON.stringify({
            screen: id,
            connect: true
        }));
        model.ready = true;
        console.log('listening for connections on screen: ', id);
    });
    ws.addEventListener('close', () => {
        model.ready = false;
        setTimeout(listenForConnections.bind(this, id), 3000);
    });
}

function createID() {
    const min = 100000000000;
    const max = 99999999999999;
    const int =  Math.round(min + (Math.random() * (max - min)));
    let string = int.toString(36);

    while (string.length < 9) {
        string = `0${string}`;
    }

    return string;
}

function getDisplayCodeParts(ID) {
    return [
        ID.substring(0, 3).toUpperCase(),
        ID.substring(3, 6).toUpperCase(),
        ID.substring(6, 9).toUpperCase()
    ]
}

Object.assign(window, require('./control/control'));
